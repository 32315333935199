@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 2 92% 56%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 2 92% 36%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    background-color: #f3f4f6;
    overflow-x: hidden;
  }
}

.page-height {
  min-height: calc(100vh - 202px);
}

.ck-editor__editable a {
  color: #3b82f6;
}

.ck-editor__editable {
  width: 100%;
  height: 234px;
  padding: 0 20px !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
}

.editor-light .ck-editor__editable {
  border: 1px solid hsl(214.3 31.8% 91.4%) !important;
  background-color: hsl(0 0% 100%) !important;
  color: hsl(222.2 84% 4.9%) !important;
}
.editor-dark .ck-editor__editable {
  border: 1px solid hsl(217.2 32.6% 17.5%) !important;
  background-color: hsl(222.2 84% 4.9%) !important;
  color: hsl(210 40% 98%) !important;
}

.assign-ticket-editor .ck-editor__editable {
  height: 400px !important;
}

.copy-issue-editor .ck-editor__editable {
  height: 550px !important;
}

.text-editor-dark .ck-editor__editable {
  background-color: var(--background) !important;
  color: var(--foreground) !important;
  border: 1px solid #1e2a39 !important;
}

.text-editor-error .ck-editor__editable {
  border: 1px solid #dc2626 !important;
}

.ck-editor__top {
  display: none !important;
}

.ck.ck-powered-by {
  display: none !important;
}

.editor-modal .textarea-disabled .ck-editor__editable {
  background-color: #2b3952 !important;
}

.textarea-disabled .ck-editor__editable {
  opacity: 0.5;
  cursor: not-allowed;
}

.textarea-disabled.editor-light .ck-editor__editable {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: hsla(0, 0%, 100%, 0.836) !important;
}
